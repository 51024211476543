import React, {useMemo, useState} from 'react';
import Trash from "../icons/Trash";
import gql from "graphql-tag";
import Pen from "../icons/Pen";
import Transport from "../icons/Transport";
import {
    Button,
    Modal,
    Form,
    ButtonGroup,
    Row,
    Col,
    ModalHeader,
    Label,
    FormGroup,
    ModalFooter, ModalBody, Input
} from "reactstrap";
import {Link} from "react-router-dom";
import moment from 'moment';
import {Formik} from 'formik';
import {useStdMutation} from "../../spiralStair/lib/Util";
import {useMutation} from "@apollo/client";
import FormRenderer from "../../common/FormRenderer";
import NotificationPopup from "../../common/lib/NotificationPopup";
import CreateArticleRow from "./CreateArticleRow";
import {formatCurrency} from "../../UTIL";
import {withRouter} from "react-router";
import {InventoryRoute} from "../InventoryRouters";
import ColumnId from "../../common/data-grid/columns/ColumnId";
import NonPaginatedDataGrid from "../../common/data-grid/NonPaginatedDataGrid";
import {useLocale} from "../../LocaleProvider";

const KEY_TABLE = "TABLE_INVENTORY_PURCHASE_ARTICLE_ROWS"

const updateArticleRowMutation = gql`
    mutation($id:ID!,$articleRow:ArticleRowUpdateInputType!)
    {
        updateArticleRow(id:$id,articleRow: $articleRow)
        {
            id
        }
    }
`

export const DEFAULT_OPTIONS = "pcs"
export const unitOptions = ["none", DEFAULT_OPTIONS, "m", "kg", ]

const CreateFreeTextArticleRow = (props) => {
    const [open, setOpen] = useState();
    const {t} = useLocale()
    const purchaseOrderId = props.purchaseOrderId

    const [create] = useStdMutation("Create article row", gql`
        mutation createArticleRow($ar:ArticleRowCreateInputType!)
        {
            createArticleRow(articleRow: $ar)
            {
                id
            }
        }`, {refetchQueries: ["getInventoryPurchaseOrder"]});
    const toggle = () => setOpen(o => !o);

    return <div>
        <Modal isOpen={open} toggle={toggle}>
            <Formik initialValues={{name: "", price: 0, quantity: 0, unit: DEFAULT_OPTIONS}}
                    onSubmit={async ({price, quantity, name, unit}) => {
                        await create({
                            variables: {
                                ar: {
                                    name,
                                    price: parseFloat(price),
                                    quantity: parseFloat(quantity),
                                    unit: unit,
                                    purchaseOrderId
                                }
                            }
                        });
                        toggle();
                    }}>
                {({isSubmitting, values, handleChange, handleSubmit}) => {
                    return <Form onSubmit={handleSubmit}>
                        <ModalHeader toggle={toggle}>{t('Create Article Row')}</ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label>
                                    {t('Name')}
                                </Label>
                                <Input value={values.name} name={"name"} onChange={handleChange}/>
                            </FormGroup>
                            <FormGroup>
                                <Label>
                                    {t('Quantity')}
                                </Label>
                                <Input type={"number"} lang={"en-150"} name={"quantity"} min={0} step={0.01}
                                       value={values.quantity} onChange={handleChange}/>
                            </FormGroup>
                            <FormGroup>
                                <Label>
                                    {t('Price')}
                                </Label>
                                <Input type={"number"} lang={"en-150"} value={values.price} name={"price"}
                                       onChange={handleChange}/>
                            </FormGroup>
                            <FormGroup>
                                <Label>{t('Unit')}</Label>
                                <Input type={"select"} lang={"en-150"} value={values.unit}
                                       name={"unit"} key={"unit"}
                                       onChange={handleChange}>
                                    {unitOptions.map((e) => <option>{e}</option>)}
                                </Input>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button disabled={isSubmitting} type="submit" color={"success"}>{t('Submit')}</Button>
                            <Button onClick={toggle}>{t('Cancel')}</Button>
                        </ModalFooter>
                    </Form>
                }}
            </Formik>
        </Modal>
        <Button color={"secondary"} onClick={() => setOpen(true)}>{t('Create Free text Row')}</Button>
    </div>
};

const EditArticleRow = ({articleRow, toggle}) => {
    const [update] = useMutation(updateArticleRowMutation, {refetchQueries: ["getInventoryPurchaseOrder"]});
    const {t} = useLocale()

    if (articleRow) {
        return <Modal isOpen={true} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                {t('Edit Article Row')}
            </ModalHeader>
            <ModalBody>
                <FormRenderer onSubmit={async ({object}) => {
                    if (articleRow.article && !object.price) {
                        window.alert('The price cannot be 0')
                        return
                    }

                    try {
                        await update({
                            variables:
                                {
                                    id: articleRow.id,
                                    articleRow: object
                                }
                        })
                        toggle();
                        NotificationPopup.success(`Updated articlerow`);
                    } catch (e) {
                        NotificationPopup.error(`Failed to update article row ${e.message}`);
                    }
                }} object={articleRow} formDefinition={[
                    {name: "quantity", type: "currency"},
                    {name: "name", required: true},
                    {name: "price", type: "currency"},
                    { name: "unit", type: "select", disableSort: true, options: unitOptions.map((e) => ({id: e, name: e}))}
                ]}/>
            </ModalBody>
        </Modal>
    } else {
        return null;
    }

};

const CreateStockTransaction = ({articleRow, toggle}) => {
    const [createStockTransaction] = useStdMutation(`create stock transaction`, gql`
        mutation createStockTransaction($id:ID!,$quantity:Float!,$received:DateTime)
        {
            createStockTransactionArticleRow(articleRowId: $id,quantity: $quantity,received: $received)
            {
                id
            }
        }
    `, {refetchQueries: ["getInventoryPurchaseOrder"]})
    const [quantity, setQuantity] = useState(0);
    const today = moment().format('YYYY-MM-DD');
    const [receivedDate, setReceivedDate] = useState(today);
    const {t} = useLocale()

    if (articleRow) {
        return <Modal isOpen={true} toggle={toggle}>
            <ModalHeader toggle={toggle}>{t('Create stock transaction')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <p>
                            {t('Article No')}: {articleRow?.article?.artNo}
                        </p>
                        <p>
                            {t('Article Name')}: {articleRow.name}
                        </p>
                        {t('Quantity')}: &nbsp;
                        <span style={{color: "green"}}>{articleRow.receivedQuantity}</span>
                        &nbsp; / &nbsp;
                        <span style={{color: "orange"}}>{articleRow.quantity}</span>
                    </Col>
                </Row>
                <Row>
                    <Col lg={8} sm={12}>
                        <FormGroup>
                            <Label>
                                {t('Quantity')}
                            </Label>
                            <Input type={"number"} value={quantity} min={0} step={1}
                                   onChange={e => setQuantity(e.target.value)}/>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg={8} sm={12}>
                        <FormGroup>
                            <Label>
                                {t('Received')}
                            </Label>
                            <Input type={"date"} value={receivedDate} max={today}
                                   onChange={e => setReceivedDate(e.target.value)}/>
                        </FormGroup>
                    </Col>
                </Row>
                <Row style={{justifyContent: "space-evenly"}}>
                    <Col sm={2}>
                        <Button color={"secondary"} onClick={toggle}>
                            {t('Cancel')}
                        </Button>
                    </Col>
                    <Col sm={2}>
                        <Button color={"primary"}
                                onClick={async () => {
                                    await createStockTransaction({
                                        variables:
                                            {
                                                id: articleRow.id,
                                                quantity: articleRow.quantity - articleRow.receivedQuantity,
                                                received: receivedDate
                                            }
                                    });
                                    toggle();
                                }}>
                            {t('All received')}
                        </Button>
                    </Col>
                    <Col sm={2}>
                        <Button color={"success"} onClick={async () => {
                            await createStockTransaction({
                                variables:
                                    {
                                        id: articleRow.id,
                                        quantity: parseFloat(quantity),
                                        received: receivedDate
                                    }
                            })
                            toggle();
                        }}>
                            {t('Submit')}
                        </Button>
                    </Col>

                </Row>
            </ModalBody>
        </Modal>
    } else {
        return null;
    }

}


const FreeTextRowTransaction = ({articleRow, toggle}) => {
    const [updateArticleRow] = useStdMutation(`create stock transaction`, gql`
        mutation updateArticleRow($id:ID!, $textRowReceivedQuantity:Float!){
            updateArticleRow(articleRow:{textRowReceivedQuantity:$textRowReceivedQuantity}, id:$id){
                id
                textRowReceivedQuantity
            }
        }
    `, {refetchQueries: ["getInventoryPurchaseOrder"]})
    const [quantity, setQuantity] = useState(0);
    if (articleRow) {
        return <Modal isOpen={true} toggle={toggle}>
            <ModalHeader toggle={toggle}>Create stock transaction</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <p>
                            Article No: {articleRow?.article?.artNo}
                        </p>
                        <p>
                            Article Name: {articleRow.name}
                        </p>
                        Quantity: &nbsp;
                        <span style={{color: "green"}}>{articleRow.textRowReceivedQuantity}</span>
                        &nbsp; / &nbsp;
                        <span style={{color: "orange"}}>{articleRow.quantity}</span>
                    </Col>
                </Row>
                <Row>
                    <Col lg={8} sm={12}>
                        <FormGroup>
                            <Label>
                                Quantity
                            </Label>
                            <Input type={"number"} value={quantity} min={0} step={1}
                                   onChange={e => setQuantity(e.target.value)}/>
                        </FormGroup>
                    </Col>
                </Row>
                <Row style={{justifyContent: "space-evenly"}}>
                    <Col sm={2}>
                        <Button color={"secondary"} onClick={toggle}>
                            Cancel
                        </Button>
                    </Col>
                    <Col sm={2}>
                        <Button color={"primary"}
                                onClick={async () => {
                                    await updateArticleRow({
                                        variables:
                                            {
                                                id: articleRow.id,
                                                textRowReceivedQuantity: articleRow.quantity - articleRow.textRowReceivedQuantity
                                            }
                                    });
                                    toggle();
                                }}>
                            All received
                        </Button>
                    </Col>
                    <Col sm={2}>
                        <Button color={"success"} onClick={async () => {
                            await updateArticleRow({
                                variables:
                                    {
                                        id: articleRow.id,
                                        textRowReceivedQuantity: parseFloat(quantity)
                                    }
                            })
                            toggle();
                        }}>
                            Submit
                        </Button>
                    </Col>

                </Row>
            </ModalBody>
        </Modal>
    } else {
        return null;
    }

}

const ArticleRows = (props) => {
    const {articleRows, currency, tenantId} = props

    const {params} = props.match;
    const purchaseOrderId = parseInt(params.id);

    const [remove] = useMutation(gql`mutation removeArticleRow($id:ID!){removeArticleRow(id:$id)}`,
        {refetchQueries: ["getInventoryPurchaseOrder"]});
    const [articleRow, setArticleRow] = useState(null);
    const [stockTransactionArticleRow, setStockTransactionArticleRow] = useState(null);
    const [freeTextArticleRow, setFreeTextArticleRow] = useState(null);
    const {t} = useLocale()

    const columns = useMemo(() => {
        return [
            {
                field: "actions",
                renderCell: (params) => {
                    const articleRow = params.row
                    const {article} = articleRow;
                    return <>
                        <div className={"link"} onClick={async () => {
                            try {
                                if (window.confirm(`${t('Are you sure you want to delete article row')}: ${articleRow.name}`)) {
                                    await remove({variables: {id: articleRow.id}});
                                    NotificationPopup.info(`${t('Removed article row')} ${articleRow.name}`);
                                }
                            } catch (e) {
                                NotificationPopup.error(`${t('Failed to remove article row')}.. ${e.message}`);
                            }
                        }}>
                            <Trash/>
                        </div>
                        <div className={"link"} style={{margin: "0 8px"}} onClick={() => setArticleRow(articleRow)}>
                            <Pen/></div>
                        <div className={"link"} onClick={() => {
                            if (article) {
                                setStockTransactionArticleRow(articleRow);
                            } else {
                                setFreeTextArticleRow(articleRow);
                            }
                        }}><Transport/></div>
                    </>
                }
            },

            {
                field: "article",
                headerName: "Article Number",
                renderCell: (params) => {
                    const article = params.value
                    if (article) {
                        return <Link to={`${InventoryRoute.ARTICLE_DETAIL}/${article.id}`}>{article.artNo}</Link>
                    }
                    return '-'
                }
            },
            {field: "name"},
            {
                field: "quantity",
                type: "number",
                renderCell: (params) => {
                    const {article, quantity, receivedQuantity, textRowReceivedQuantity} = params.row;
                    return <>
                     <span
                         style={{color: "green"}}>{article ? receivedQuantity : (textRowReceivedQuantity || 0)}</span> / <span
                        style={{color: "orange"}}>{quantity}</span>
                    </>
                }
            },
            {field: "unit"},
            {
                field: "price",
                renderCell: (params) => {
                    return <>{formatCurrency(params.value)} {currency}</>
                }
            },
            {
                field: "amount",
                renderCell: (params) => {
                    const {price, quantity} = params.row;
                    return <>
                        {price && quantity && formatCurrency(price * quantity)} {currency}
                    </>
                }
            },
            ColumnId()
        ]
    }, [currency, remove, t])

    return <div>
        <EditArticleRow toggle={() => setArticleRow(null)} articleRow={articleRow}/>

        <ButtonGroup>
            <CreateArticleRow currency={currency} tenantId={tenantId}/>
            <CreateFreeTextArticleRow purchaseOrderId={purchaseOrderId}/>
        </ButtonGroup>

        <CreateStockTransaction toggle={() => setStockTransactionArticleRow(null)}
                                articleRow={stockTransactionArticleRow}/>
        <FreeTextRowTransaction toggle={() => setFreeTextArticleRow(null)}
                                articleRow={freeTextArticleRow}/>
        <NonPaginatedDataGrid
            style={{marginTop: "20px"}}
            definition={{
                tableKey: KEY_TABLE,
                columns: columns,
                pageSize: 20,
                disableTooBar: true
            }}
            data={articleRows}/>
    </div>
}

export default withRouter(ArticleRows)