import {Button, Card, CardBody, CardFooter, CardHeader, Spinner} from "reactstrap";
import React, {useCallback, useMemo, useState} from "react";
import NewFormRenderer from "../NewFormRenderer";
import {
    useUpdateArticleMutation
} from "../../generated/graphql";
import {WeldabilityTypes} from "./WeldabilityTypes";
import {useLocale} from "../../LocaleProvider";
import {useMutationHandler} from "../../custom-hook/useMutationHandler";

const WeldabilityCard = ({article}) => {
    const {t} = useLocale();
    const [formData, setFormData] = useState({weldability: article.weldability})
    const {executeMutation, loading} = useMutationHandler(useUpdateArticleMutation)

    const formDefinition = useMemo(() => {
        return [{name: "weldability", type: "select", options: WeldabilityTypes}]
    }, []);

    const onChange = useCallback(async (key, value) => {
        setFormData({weldability: value})
    }, []);

    const save = useCallback(async () => {
        await executeMutation({variables: {id: article.id, article: formData}});
    }, [article.id, formData, executeMutation]);

    return (<Card>
        <CardHeader>
            {t('Weldability')}
        </CardHeader>
        <CardBody>
            <NewFormRenderer
                formDefinition={formDefinition}
                columns={1}
                object={formData}
                onChange={onChange}/>
        </CardBody>
        <CardFooter>
            <Button size={"sm"} color={"success"} disabled={loading} onClick={save}>
                {t('Save')}
                {loading && <Spinner color={"warning"}/>}
            </Button>
        </CardFooter>
    </Card>);
}

export default WeldabilityCard;