import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types'
import { Button, ButtonGroup, Card, CardBody, CardHeader, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import GenericWorkflowForm from "./GenericWorkflowForm";
import gql from "graphql-tag";
import TaskFragment from '../new-task/graphql/TaskFragment.graphql';
import {graphql} from "@apollo/client/react/hoc";
import BPMDiagram from "./BPMDiagram";
import DetailRenderer from "./DetailRenderer";
import Auth, {IsAdmin} from "../Auth";
import ClaimTaskMutation from '../new-task/graphql/ClaimTask.graphql';
import ReassignTaskButton from "./ReassignTaskButton";
import StdQuery from "./StdQuery";
import Paragraph from "./Paragraph";

import {CommentTable} from "./task-workflow/comment/CommentTable";
import {withLocale} from "../LocaleProvider";
import {isProd} from "../workflow-forms/DrawJob/util";

const query = gql`    
    query tasks($processInstanceId:String,$orderId:ID,$quoteId:ID,$purchaseOrderId:ID)
    {
        tasks(processInstanceId:$processInstanceId,orderId:$orderId,quoteId:$quoteId,purchaseOrderId:$purchaseOrderId)
        {
            ...TaskFragment
            claimable
        }
    }
    ${TaskFragment}
`;
class WorkflowDetail extends Component {
    constructor() {
        super();
        this.state = {
            editing: false,
            currentTask: null,
        };
        this.toggle = this.toggle.bind(this);
    }

    claimTask = async (task) => {
        const {t} = this.props
        if (window.confirm(`${t('Are you sure you wish to assign the task')}: "${task.name}" ${t('to you')}?`)) {
            await this.props.claimTask({
                variables: {
                    id: task.id,
                },
                refetchQueries: ["tasks"]
            });
            this.setState({editing: true, currentTask: {...task, assignee: Auth.getUsername()}});
        }
    };

    toggle() {
        this.setState({ editing: !this.state.editing, currentTask: null });
    }

    onReassignTaskUpdateSuccess = async ({username}) => {
        this.setState({ currentTask: {...this.state.currentTask, assignee: username}});
    }

    overview = (tasks) => {
        const {t} = this.props
        const toggle = () => this.setState({ currentTask: null });
        const task = _.find(tasks, { id: this.state.currentTask.id });
        const { name, description, createdAt, assignee, owner, processDefinitionName, claimable } = task
        const comments = task?.variables?.comments;

        return <Modal size="lg" isOpen={true} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                <p>Workflow: <strong>{processDefinitionName}</strong></p>
                <p>{name}</p>
            </ModalHeader>
            <ModalBody>
                <DetailRenderer hideUpdateInfo object={{ name, description, createdAt, owner, assignee }} />
                {task.processInstanceId && <BPMDiagram width={750} processInstanceId={this.state.currentTask.processInstanceId}
                    activityIds={this.state.currentTask.activityIds} />}
                {(comments && comments.length>0) && <CommentTable comments={comments}/>}

                <Button onClick={() => this.setState({ editing: false, currentTask: null })}>
                    Back
                </Button>
                &nbsp;
                &nbsp;
                <ButtonGroup>
                    {Auth.getUsername() === assignee &&
                        <Button color="primary" onClick={() => this.setState({ editing: true })}>
                            {t('Open task for completion')}
                        </Button>}
                    {claimable &&
                        <Button color="primary" onClick={() => this.claimTask(task)}>
                            {t("Claim Task")}
                        </Button>}
                    {
                        isProd ? <IsAdmin>
                                <ReassignTaskButton task={this.state.currentTask} onSuccess={this.onReassignTaskUpdateSuccess} />
                            </IsAdmin>
                            : <ReassignTaskButton task={this.state.currentTask} onSuccess={this.onReassignTaskUpdateSuccess} />
                    }
                </ButtonGroup>
            </ModalBody>
        </Modal>
    };

    content(tasks) {
        const {t} = this.props
        if (!tasks || tasks.length === 0) {
            return <p>No tasks</p>;
        }
        return <div style={{ maxWidth: "190px" }}>
            {this.state.currentTask && !this.state.editing && this.overview(tasks)}
            {_.map(tasks, task => <Row key={task.id}>
                <Card>
                    <CardHeader style={{ padding: "0.5rem" }}>
                        {_.truncate(task.name, { length: 47 })} {task?.variables?.subject ?? ""}
                    </CardHeader>
                    <CardBody style={{ padding: "10px 10px" }}>
                        <Paragraph style={{ marginBottom: "10px" }}>{task.description ?? task.variables?.description}</Paragraph>
                        <ButtonGroup style={{ margin: "0 auto" }} size={"sm"} vertical>
                            {Auth.getUsername() === task.assignee &&
                                <Button color="primary" onClick={() => this.setState({ currentTask: task, editing: true })}>
                                    {t('Open task for completion')}
                                </Button>}
                            {task.claimable &&
                                <Button color="primary" onClick={() => this.claimTask(task)}>
                                    {t("Claim Task")}
                                </Button>}
                            <Button onClick={() => this.setState({ currentTask: task })}>
                                {t("View task overview")}
                            </Button>
                        </ButtonGroup>
                    </CardBody>
                </Card>
            </Row>)}
        </div>
    }

    body(tasks) {
        return <div>
            {this.state.currentTask && <GenericWorkflowForm isOpen={this.state.editing} task={this.state.currentTask} toggle={this.toggle} />}
            {this.content(tasks)}
        </div>
    }

    render() {
        const { quoteId, orderId, processInstanceId, purchaseOrderId } = this.props;
        if (!quoteId && !orderId && !processInstanceId && !purchaseOrderId) {
            return null;
        }
        return <div>
            <StdQuery query={query} variables={{ processInstanceId, orderId, quoteId, purchaseOrderId }}>
                {data => {
                    return this.body(data.tasks);
                }}
            </StdQuery>
        </div>
    }
}

WorkflowDetail.propTypes =
{
    processInstanceId: PropTypes.string,
    orderId: PropTypes.string,
    quoteId: PropTypes.string,
    purchaseOrderId: PropTypes.string,
};



export default graphql(ClaimTaskMutation, { name: "claimTask" })(withLocale(WorkflowDetail));



