import React, {memo, useMemo} from 'react';
import {withApollo} from '@apollo/client/react/hoc'
import {Link} from 'react-router-dom';
import ColumnAssignee from "../../common/data-grid/columns/ColumnAssignee";
import ColumnsStatus from "../../common/data-grid/columns/ColumnStatus";
import {formatCurrency, orderTotal, PurchaseOrderStatus} from "../../UTIL";
import ColumnCreatedAt from "../../common/data-grid/columns/ColumnCreatedAt";
import ColumnId from "../../common/data-grid/columns/ColumnId";
import PaginatedDataGrid from "../../common/data-grid/PaginatedDataGrid";
import {Comparator, PurchaseOrdersV2InInventoryDocument} from "../../generated/graphql";
import ColumnTenantId from "../../common/data-grid/columns/ColumnTenantId";
import ColumnDate from "../../common/data-grid/columns/ColumnDate";
import {articleDetailLink} from "../../utils/ArticleUtils";
import {QUERY_PARAMS_IS_INVENTORY, SUPPLIER_ID_ESPL, SUPPLIER_ID_ESPR, TENANT_ID_ESPR} from "../../Constants";
import {ExportGroup} from "../../types/data-grid/ExportGroup";
import {subDays} from "date-fns";
import {useLocale} from "../../LocaleProvider";
import {useTenant} from "../../TenantProvider";

const KEY_TABLE = 'TABLE_KEY_INVENTORY_ORDER_LIST'

const ArticleRowCell = memo(({articleRows}: any) =>
{
    const {t} = useLocale()

    if (!articleRows)
    {
        return <div>-</div>
    }

    const nonStockArticleRows = articleRows.filter((ar) => ar.article && !ar.isStockArticle)
    const stockArticles = articleRows.filter((ar) => ar.isStockArticle)
    return <div>
        {nonStockArticleRows.map(ar =>
            {
                const article = ar.article
                if (!article)
                {
                    return '-'
                }
                return <p key={article.id} style={{margin: 0}}>
                    {articleDetailLink(article, article.artNo, true)}
                </p>
            }
        )}
        {stockArticles.length > 0 &&
            <div style={{margin: 0}}>{stockArticles.length} {t('Stock articles')}</div>}
    </div>
})

const columns = () =>
{
    return [
        {
            field: 'number',
            headerName: 'Number',
            searchable: true,
            renderCell: (params) =>
            {
                return <Link to={`/purchaseOrderDetail/${params.id}?${QUERY_PARAMS_IS_INVENTORY}=true`}>{params.value}</Link>
            },
        },
        ColumnsStatus(PurchaseOrderStatus),
        ColumnTenantId(),
        ColumnDate('deliveryDate', 'Preferred Delivery Date'),
        ColumnDate('order.preferredDeliveryDate', 'Customer Order Preferred Delivery Date'),
        {
            field: 'articleRows',
            sortable: false,
            filterable: false,
            renderCell: (params) => <ArticleRowCell articleRows={params.value}/>,
        },
        {
            field: 'value',
            headerName: 'Value',
            filterable: false,
            sortable: false,
            type: 'number',
            renderCell: (params) =>
            {
                const purchaseOrder = {
                    articleRows: params.row.articleRows,
                    shippingCost: 0, packagingCost: 0
                }
                return <div style={{textAlign: 'right'}}>{formatCurrency(orderTotal(purchaseOrder))} {params.row.currency}</div>
            },
        },
        ColumnCreatedAt(),
        ColumnAssignee('creator.username', 'Created by', 'creatorId'),
        ColumnId(),
    ]
}

const defaultSort = {sortModel: [{field: 'createdAt', sort: 'desc'}]}

const InventoryOrderListPage = () =>
{
    const {t} = useLocale()
    const {tenantId} = useTenant()

    const supplierName = useMemo(() =>
    {
        if (tenantId === TENANT_ID_ESPR)
        {
            return SUPPLIER_ID_ESPR
        }

        return SUPPLIER_ID_ESPL
    }, [tenantId]);

    const filterButtonsDefinition = useMemo(() =>
    {
        const filterItemUndeliveredStatus = {
            field: 'status',
            value: ["inProduction", "drawqueue"],
            operator: 'in'
        }
        const today = new Date()

        return [
            {
                name: t("Undelivered"),
                filters: {items: [filterItemUndeliveredStatus]}
            },
            {
                name: t("Undelivered orders 7 days ahead"),
                filters: {
                    items: [
                        filterItemUndeliveredStatus,
                        {field: 'deliveryDate', value: subDays(today, 7), operator: 'gte'},
                        {field: 'deliveryDate', value: today, operator: 'lte'},
                    ],
                }
            },
            {
                name: t("Undelivered orders 30 days ahead"),
                filters: {
                    items: [
                        filterItemUndeliveredStatus,
                        {field: 'deliveryDate', value: subDays(today, 30), operator: 'gte'},
                        {field: 'deliveryDate', value: today, operator: 'lte'},
                    ],
                }
            },
            {
                name: t("In production"),
                filters: {items: [{field: 'status', value: ["inProduction"], operator: 'in'}]},
            },
        ]
    }, [t])

    return (
        <PaginatedDataGrid
            definition={{
                tableKey: KEY_TABLE,
                columns: columns(),
                initState: {
                    sorting: defaultSort,
                    filter: {
                        filterModel: {
                            items: [{
                                field: "status", operator: "in",
                                value: ["drawqueue", 'inProduction', 'sent', 'ready']
                            }]
                        }
                    }
                },
                exportGroup: ExportGroup.admin,
                buttons: filterButtonsDefinition
            }}
            query={PurchaseOrdersV2InInventoryDocument}
            persistFilter={[{key: 'supplier.id', value: supplierName, comparator: Comparator.Equals}]}
        />
    )
}


export default withApollo(InventoryOrderListPage);
