import React, {useCallback, useEffect, useMemo, useState} from 'react';
import gql from "graphql-tag";
import GenericWorkflowForm from "../common/GenericWorkflowForm";
import ModalForm from "../common/ModalForm";
import FormRenderer from "../common/FormRenderer";
import {camundaDateFix,} from "../UTIL";
import Auth from "../Auth";
import {Badge, Spinner} from "reactstrap";
import StdQuery from "../common/StdQuery";
import GetTaskQuery from './graphql/GetTask.graphql'
import {get} from 'lodash';
import EventEmitter from '../common/EventEmitter';
import logger from '../Logger';
import 'moment/locale/sv';
import 'moment/locale/nb';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import {Col, Row} from "reactstrap";
import P from '../common/Paragraph';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import NonPaginatedDataGrid from "../common/data-grid/NonPaginatedDataGrid";
import ColumnDate from "../common/data-grid/columns/ColumnDate";
import ColumnCreatedAt from "../common/data-grid/columns/ColumnCreatedAt";
import {Box} from "@mui/material";
import {FilterOperatorStringWithEmpty} from "../common/data-grid/FilterOperatorDefinitions";
import {isInventoryPath} from "../inventory/InventoryRouters";
import {
    PROCESS_DEFINITION_KEY_ESPL_DRAW_JOB,
    PROCESS_DEFINITION_KEY_ESPL_PURCHASE_ORDER,
} from "../Constants";
import {useLocale} from "../LocaleProvider";
import {useMutation} from "@apollo/client";
import {useAllTasksQuery, useClaimTaskMutation, useCreateTaskMutation} from "../generated/graphql";
import {withRouter} from "react-router";
import BoldTitleWithMessage from "../common/BoldTitleWithMessage";

const TABLE_KEY = "TABLE_TASK"

const filterAssignedToMe = {field: "assignee", operator: "equals", value: Auth.getUsername()}

const OrderDescription = ({task, onClick, type, number}) => {
    return <div>
        <Row>
            <Col>
                <P style={{fontSize: "1em"}}>
                    {task.assignee !== Auth.getUsername() && task.name}
                    {task.assignee === Auth.getUsername() &&
                        <span onClick={() => onClick(task)} className="link">
                            {_.truncate(task.name, {length: 50})}
                        </span>}
                </P>
            </Col>
        </Row>
        <Row>
            <Col>
                <BoldTitleWithMessage title={type} message={number}/>
            </Col>
        </Row>
    </div>
}

const FollowupDescription = ({task, onClick}) => {
    return <div>
        <Row>
            <Col>
                <P style={{fontSize: "1em"}}>
                        <span onClick={() => onClick()} className="link">
                            {task?.variables?.subject ?? task?.name ?? ""}
                        </span>
                </P>
            </Col>
        </Row>
        <Row>
            <Col>
                <P>
                    <strong>Quote number:</strong> {task?.quote?.number}
                </P>
            </Col>
        </Row>
    </div>
}

const GenericTaskDescription = ({task, onClick}) => {
    return <div>
        <Row>
            <Col>
                <P>
                    <span
                        style={{fontSize: "1em"}}
                        className="link"
                        onClick={() => onClick()}>{task.name}
                    </span>
                </P>
            </Col>
        </Row>
        <Row>
            <Col>
                <P>
                    {!task.processDefinitionName && task.variables.quoteId && task.quote &&
                        <React.Fragment><strong>Quote number:</strong> {task.quote.number}</React.Fragment>
                    }
                    {!task.processDefinitionName && task.variables.orderId && task.order &&
                        <React.Fragment><strong>Order number:</strong> {task.order.number}</React.Fragment>
                    }
                    {!task.processDefinitionName && task.variables.companyId && task.company &&
                        <React.Fragment><strong>Company:</strong> {task.company.name}</React.Fragment>
                    }
                </P>
            </Col>
        </Row>
    </div>
}

const CreateTaskModal = ({createNewTask}) => {
    const {t} = useLocale()

    const formDefinition = useMemo(() => {
        return [
            {name: "name", required: true},
            {name: "description", type: "textarea"},
            {name: "due", label: "Due Date", type: "datetime", defaultValue: new Date()},
            {
                type: "infotext",
                key: "infotext1",
                value: t("Please select only 1 of the optional values below")
            },
            {
                name: 'companyId', type: "search",
                hint: <p>{t('Enter company name')}</p>,
                types: ["company"],
                filter: obj => obj.type === "company",
            },
            {
                name: 'quoteId', type: "search",
                hint: <p>{t('Enter quote number')}</p>,
                types: ["quote"],
                filter: obj => obj.type === "quote",
            },
            {
                name: 'orderId', type: "search",
                hint: <p>{t('Enter order number')}</p>,
                types: ["order"],
                filter: obj => obj.type === "order",
            }
        ]
    }, [t])

    return <ModalForm title={t('Create new task')} activationButtonText={t('Create new task')}>
        {toggle =>
            <FormRenderer formDefinition={formDefinition}
                          onSubmit={form => createNewTask(form.object, toggle)}/>
        }
    </ModalForm>
}

const TaskTableBox = ({children}) => {
    return <Box style={{marginTop: '8px'}}
                sx={{
                    width: '100%',
                    '& .UploadCustomerDrawings': {
                        backgroundColor: '#E6E6FA',
                    },
                    '& .ProvideMoreInformation': {
                        backgroundColor: '#FFC0CB',
                    },
                    '& .ReviewMoreInformation': {
                        backgroundColor: '#F0FFF0',
                    },
                    '& .ReviseDrawing': {
                        backgroundColor: '#FAFAD2',
                    },
                    '& .SendCustomerDrawing': {
                        backgroundColor: '#F5DEB3',
                    },
                    '& .CustomerResponse': {
                        backgroundColor: '#FFE4E1',
                    },
                    '& .UploadProductionDrawing': {
                        backgroundColor: '#54FF9F',
                    },
                    '& .ReviewDrawings': {
                        backgroundColor: '#FF82AB',
                    },
                }}>
        {children}
    </Box>
}

const TaskListPage = (props) => {
    const [currentTask, setCurrentTask] = useState(null)
    const [newTasks, setNewTasks] = useState([])

    const [clearNewTasksMutation] = useMutation(gql`mutation { clearNewTasks }`)
    const [createTaskMutation] = useCreateTaskMutation({refetchQueries: ["AllTasks"]})
    const [claimTaskMutation] = useClaimTaskMutation({refetchQueries: ["AllTasks"]})
    const {data: tasks, loading, error} = useAllTasksQuery()

    const toggle = useCallback(() => {
        setCurrentTask(null)
    }, []);

    const createNewTask = useCallback(async (task, toggle) => {
        toggle();
        const {name, due, description, orderId, quoteId, companyId} = task;
        await createTaskMutation({
            variables: {
                task:
                    {
                        name, description, due: camundaDateFix(due),
                        variables:
                            {
                                orderId:
                                    {
                                        value: orderId,
                                        type: "Integer"
                                    },
                                quoteId:
                                    {
                                        value: quoteId,
                                        type: "Integer"
                                    },
                                companyId:
                                    {
                                        value: companyId,
                                        type: "Integer"
                                    }
                            }
                    }
            },
        });
    }, [createTaskMutation]);

    const claimTask = useCallback(async (task) => {
        if (window.confirm(`Are you sure you wish to assign the task: "${task.name}" to yourself?`)) {
            await claimTaskMutation({
                variables: {
                    id: task.id,
                },
            });
            setCurrentTask(task)
        }
    }, [claimTaskMutation])

    const quicklyFilterButtonDefinitions = useMemo(() => {
        return [
            {
                name: 'Assigned to me',
                filters: {items: [filterAssignedToMe]}
            },
            {
                name: 'Pooled tasks',
                filters: {items: [{field: "assignee", operator: "isEmpty"}]}
            }
        ]
    }, [])

    const isOnInventoryPath = useMemo(() => {
        return isInventoryPath(props.location)
    }, [props.location])

    const columns = useMemo(() => {
        return [
            {
                field: "assignee",
                customFilterOperators: FilterOperatorStringWithEmpty,
                renderCell: (params) => {
                    const value = params.value
                    const task = params.row
                    return <>
                        {!value && <span className='link' onClick={() => claimTask(task)}>
                                    Claim task
                                </span>}
                        {task.assignee && value}
                        &nbsp;&nbsp;
                        {newTasks.indexOf(task.id) !== -1 &&
                            <Badge pill style={{fontSize: "0.9em"}} color="primary">New</Badge>}
                    </>
                }
            },
            {
                field: "name", headerName: "Description", width: 300,
                renderCell: (params) => {
                    const task = params.row
                    const {processDefinitionName, processDefinitionKey} = task
                    const onClick = () => setCurrentTask(task)

                    const isOrderDescription = processDefinitionName === "Order" || processDefinitionName === "Draw job" ||
                        processDefinitionName === "ESPR Purchase Order" || processDefinitionName === "Purchase Order Workflow"
                    if (isOrderDescription && task.order) {
                        const order = task.order
                        return <OrderDescription
                            onClick={() => setCurrentTask(task)}
                            task={task}
                            type={isOnInventoryPath ? 'Customer order number:' : 'Order number:'}
                            number={`${order.tenantId} - ${order.number}`}/>
                    }

                    const isESPLDescription = processDefinitionKey === PROCESS_DEFINITION_KEY_ESPL_DRAW_JOB || processDefinitionKey === PROCESS_DEFINITION_KEY_ESPL_PURCHASE_ORDER
                    if (isESPLDescription && task.purchaseOrder) {
                        const purchaseOrder = task.purchaseOrder
                        return <OrderDescription
                            onClick={onClick}
                            task={task}
                            type={isOnInventoryPath ? `Order number:` : `Purchase Order number:`}
                            number={`${purchaseOrder.tenantId} - ${purchaseOrder.number}`}/>
                    }
                    if (processDefinitionName === "QuoteFollowup") {
                        return <FollowupDescription task={task} onClick={onClick}/>
                    }
                    return <GenericTaskDescription onClick={onClick} task={task}/>
                }
            },
            {
                field: 'company.name',
                headerName: 'Company name',
                filterable: false,
                valueGetter: (params) => {
                    const task = params.row
                    return task.order?.company?.name
                        ?? task.quote?.company?.name
                        ?? task?.company?.name
                        ?? ''
                },
            },
            {
                field: 'contacts',
                filterable: false,
                sortable: false,
                valueGetter: (params) => {
                    const task = params.row
                    if (task.order?.contact) {
                        return {id: task.order.contact.id, name: task.order.contact.name};
                    }
                    if (task.quote?.contact) {
                        return {id: task.quote.contact.id, name: task.quote.contact.name};
                    }
                    if (task.purchaseOrder?.order?.contact) {
                        return {
                            id: task.purchaseOrder.order.contact.id,
                            name: task.purchaseOrder.order.contact.name
                        };
                    }
                    return null
                },
                renderCell: ({value}) => {
                    if (!value) {
                        return null
                    }
                    if (isOnInventoryPath) {
                        return <div>{value?.name}</div>
                    }
                    return <Link to={`/contactDetail/${value.id}`}>{value.name}</Link>
                }
            },
            {
                field: 'project.name',
                headerName: 'Project name',
                filterable: false,
                sortable: false,
                valueGetter: (params) => {
                    const task = params.row
                    return task.order?.project?.name ?? task.quote?.project?.name ?? ''
                },
            },
            ColumnDate('due', 'Due date'),
            {
                field: 'processDefinitionName',
                headerName: "Name of workflow",
            },
            ColumnCreatedAt()]
    }, [claimTask, newTasks, isOnInventoryPath]);

    const clearNewTasks = useCallback(async () => {
        const res = await clearNewTasksMutation();
        if (res && res.data) {
            setNewTasks(res.data.clearNewTasks)
            EventEmitter.emit(`clearNewTasks`);
        }
    }, [clearNewTasksMutation]);

    useEffect(() => {
        clearNewTasks();
        return () => {
            clearNewTasks();
        };
    }, [clearNewTasks]);

    if (error) {
        if (error.networkError && error.networkError.statusCode !== 401) {
            logger.error(`Failed to fetch tasks ${error.message}`, {error});
        }
        return <p>Something went wrong when fetching tasks.</p>;
    }

    if (loading || !tasks) {
        return <Spinner/>
    }

    return <div>
        <CreateTaskModal createNewTask={createNewTask}/>
        <br/>
        {currentTask && <StdQuery noLoadingRender query={GetTaskQuery} variables={{id: get(currentTask, "id")}}>
            {data => {
                return <GenericWorkflowForm isOpen={currentTask != null} task={data.task} toggle={toggle}/>
            }}
        </StdQuery>}

        <TaskTableBox>
            <NonPaginatedDataGrid
                definition={{
                    tableKey: TABLE_KEY,
                    columns: columns,
                    initState: {
                        sorting: {sortModel: [{field: "due", sort: "desc"}]},
                        filter: {filterModel: {items: [filterAssignedToMe]}}
                    },
                    pageSize: 20,
                    buttons: quicklyFilterButtonDefinitions
                }}
                data={tasks.tasks}
                getCellClassName={(params) => {
                    const original = params.row
                    if (original && original.activityIds && original.activityIds.length > 0 && original.processDefinitionKey && original.processDefinitionKey === 'DrawJob') {
                        return original.activityIds[0]
                    }
                }}
            />
        </TaskTableBox>
    </div>
}


export default withRouter(TaskListPage);
