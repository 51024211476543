import {Card, CardBody, CardHeader} from "reactstrap";
import React from "react";
import DetailRenderer from "../common/DetailRenderer";
import NewFormRenderer from "../common/NewFormRenderer";
import _ from 'lodash';
import {useGetSupplierByProductTypeIdQueryQuery} from "../generated/graphql";
import {useLocale} from "../LocaleProvider";
export const STRAIGHT_STAIR_PRODUCT_TYPE_ID = "2";

export default ({isOrder, article, onChange, hasPurchaseOrder, isPurchaseOrder}) => {
    const {data} = useGetSupplierByProductTypeIdQueryQuery({variables:{id:STRAIGHT_STAIR_PRODUCT_TYPE_ID}})
    const {t} = useLocale()

    const {
        supplier,
    } = article;

    let formDefinition:any=[];
    if(!isOrder){
        formDefinition = [
            {name:"supplierId",label:"Supplier",type:'select',
                options:_.get(data,"productType.suppliers") || []},
            {name:"blank",type:"hidden",hideField:()=>true},
        ]
    }
    formDefinition = formDefinition.concat([
        {name:"height",type:"number",label:"Height of stair"},
        {name:"blank",type:"hidden",hideField:()=>true},
        {name:"stepWidth",type:"number"},
        {name:"stepDepth",type:"number"},
        {name:"stepType",type:"select",options:StepTypes},

        {name:"handRailType",label:"Type of railing",type:"select",options:HandRailType},
        {name:"platformS1",type:"number"},
        {name:"platformS2",type:"number"},
        {name:"surfaceTreatment",type:"select",options:SurfaceTreatment},
        {name: "inPrice", type: "number", hideField: () => hasPurchaseOrder || isPurchaseOrder},
        {name: "inPriceCurrency", type: "currencyPicker", required: true, hideField: () => hasPurchaseOrder || isPurchaseOrder},
    ]);

    return (<Card>
        <CardHeader>
            {t('Straight Stair')}
        </CardHeader>
        <CardBody>
            {isOrder && <DetailRenderer
                hideUpdateInfo
                columns={4}
                object={{
                    supplier: supplier?.name,
                }}
            />}
            <NewFormRenderer
                formDefinition={formDefinition}
                columns={2}
                object={article}
                onChange={onChange}
            />
        </CardBody>
    </Card>);
}

const StepTypes = [
    {
        id: "trasteg",
        name: "Wooden Step",
    },
    {
        id: "tarplat",
        name: "Tear plate",
    },
    {
        id: "slatplat",
        name: "Plain plate, recessed",
    },
    {
        id: "plat",
        name: "Sheet metal",
    },
    {
        id: "gd_vfz",
        name: "Grating VFZ",
    },
];

const SurfaceTreatment = [
    {
        id: "vfz",
        name: "Hot dip galvanized EN1461",
        color: "info"
    },
    {
        id: "pulverlack",
        name: "Powder coated",
        color: "success"
    },
    {
        id: "vfz_lack",
        name: "Hot dip galvanized + powder coated",
        color: "danger"
    },
    {
        id: "obehandlad",
        name: "Untreated",
        color: "primary"
    }
];

const HandRailType = [
    {
        id: "special",
        name: "Special Handrail",
    },
    {
        id: "none",
        name: "No railing",
    },
    {
        id: "industri",
        name: "Industrial railing",
    },
    {
        id: "railing",
        name: "Handrailing",
    },
    {
        id: "barn",
        name: "Childsafe railing",
    },
];
