import React, {useCallback, useEffect, useState} from 'react';
import {Button, Col, Row, Spinner} from "reactstrap";
import BackButtons from "../BackButtons";
import {
    useArticleRowLazyQuery,
    useGetArticleLazyQuery,
    useUpdateArticleMutation,
    useUpdateArticleRowMutation
} from "../../generated/graphql";
import ArticleRowEditCard from "./ArticleRowEditCard";
import {Prompt} from "react-router";
import ArticleEditCard from "./ArticleEditCard";
import _ from 'lodash';
import useIsArticleEditableHook from "../../custom-hook/useIsArticleEditableHook";
import {useLocale} from "../../LocaleProvider";
import WeldabilityCard from "./WeldabilityCard";

export default ({match}) => {
    const {id} = match.params;
    const articleRowId = match.params.articleRowId;
    const [articleOld, setArticleOld] = useState<any>(null);
    const [articleRowOld, setArticleRowOld] = useState<any>(null);
    const [articleState, setArticleState] = useState<any>(null);
    const [articleRowState, setArticleRowState] = useState<any>(null);
    const [getArticle] = useGetArticleLazyQuery();
    const [getArticleRow] = useArticleRowLazyQuery();
    const [updateArticle] = useUpdateArticleMutation({refetchQueries:["article"]})
    const [updateArticleRow] = useUpdateArticleRowMutation({refetchQueries:["articleRow"]});
    const [saveLoading, setSaveLoading] = useState(false);
    const {t} = useLocale()

    const {isEditable} = useIsArticleEditableHook(id)

    const unsavedChanges = articleState &&
        (
            !isEqual(articleState, articleOld) ||
            (articleRowState && !isEqual(articleRowState, articleRowOld))
        );

    useEffect(()=>{
        if(saveLoading) return;

        const asyncFn = async () => {
            const articleRes = await getArticle({variables:{ id }});
            const article:any = {...articleRes.data?.article};
            if(!article) return;
            article.productTypeId = article.productType?.id;
            article.supplierId = article.supplier?.id;
            setArticleOld(article);
            setArticleState(article);
            console.log("articleRowId", articleRowId)
            if(articleRowId){
                const articleRowRes = await getArticleRow({variables:{ articleRowId }});
                console.log("articleRowRes", articleRowRes)
                setArticleRowOld(articleRowRes?.data?.articleRow);
                setArticleRowState(articleRowRes?.data?.articleRow);
            }
        }
        asyncFn().then();

    }, [getArticle, getArticleRow, setArticleOld, setArticleRowOld, saveLoading, articleRowId, id]);

    const save = useCallback(async () => {
        setSaveLoading(true);
        const columns = ["name", "quantity", "unit", "price", "productTypeId", "supplierId", "description", "inPrice", "inPriceCurrency"];
        await updateArticle({variables:{id, article:_.pickBy(articleState, function(value, key) {
                return columns.includes(key);
            })
        }});
        await updateArticleRow({variables:{id:articleRowId, articleRow:_.pickBy(articleRowState, function(value, key) {
                return columns.includes(key);
            })
        }});

        setSaveLoading(false);
    }, [setSaveLoading, updateArticle, updateArticleRow, articleState, articleRowState, articleRowId, id]);

    if(!articleState || (articleRowId && !articleRowState))
    {
        return <Spinner color={"warning"} />
    }
    const {
        name:articleName,
        artNo,
    } = articleState;
    return <>
        <Prompt when={unsavedChanges} message={`You have unchanged changes, are you sure you want to leave?`} />
        <Row>
            <Col sm={1}>
                <BackButtons articleRowId={articleRowId} />
            </Col>
            <Col sm={5}>
                {articleRowId && <h2>{articleRowState?.name} {artNo}</h2>}
                {!articleRowId && <h2>{articleName} {artNo}</h2>}
            </Col>
        </Row>
        {articleRowId && <Row style={{marginBottom:"20px"}}>
            <Col sm={6}>
                <ArticleRowEditCard articleRow={articleRowState} onChange={(key, value)=>{
                    const newArticleRowState = {...articleRowState};
                    newArticleRowState[key] = value;
                    setArticleRowState(newArticleRowState);
                }}/>
            </Col>
        </Row>
        }
        <Row style={{marginBottom:"20px"}}>
            <Col sm={6}>
                <ArticleEditCard isOrder={!!articleRowState?.orderId}
                                 isPurchaseOrder={articleRowState?.purchaseOrderId != null}
                                 article={articleState}
                                 hasPurchaseOrder={(articleRowOld?.order?.purchaseOrders?.length ?? 0) > 0}
                                 onChange={(key, value)=>{
                    const newArticleState = {...articleState};
                    newArticleState[key] = value;
                    setArticleState(newArticleState);
                }}/>
            </Col>
            <Col md={3}>
                <WeldabilityCard article={articleState}/>
            </Col>
        </Row>
        {!saveLoading && <Button size={"sm"} color={"success"} disabled={!isEditable} onClick={()=>save()}>
            {t('Save')}
        </Button>}
        {saveLoading && <Spinner color={"warning"} />}
        <p>{t('Unsaved changes')}: {unsavedChanges ? <strong style={{color: "red"}}>{t("Yes")}</strong> : t("No")}</p>
    </>
};

const isEqual = (state,object)=>
{
    for(let key in state)
    {
        if(key !== "__typename"&& state.hasOwnProperty(key) && !_.isEqual(state[key],object[key]))
        {
            return false;
        }
    }
    return true;
};
